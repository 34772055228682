<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Trainee Statistics by District/Council</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label-for="search-districts" label="District(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-districts" v-model="selectedDistricts" multiple :select-size="6" :options="districtOptions"/>
                                        <b-input-group-addon>
                                            <b-button @click="selectedDistricts = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label-for="search-councils" label="Council(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-councils" v-model="selectedCouncils" multiple :select-size="6" :options="councilOptions"/>
                                        <b-input-group-addon>
                                            <b-button @click="selectedCouncils = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="year-range" label="Enrolled/Graduated Year:">
                                    <picker-of-dates id="year-range"
                                                     v-model="yearRange"
                                                     type="year"
                                                     placeholder="Select Range"
                                                     format="yyyy"
                                                     clearable range/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-checkbox v-model="includeLocalBreakdown" name="check-button" switch>
                                    Include Local Breakdown
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="4">
                                <b-form-checkbox v-model="includeOnlyEnrolledGraduated" name="check-button" switch>
                                    Only Include Enrolled and Graduated Trainees
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="4">
                                <b-form-checkbox :disabled="!!includeOnlyEnrolledGraduated" v-model="includeDeceased" name="check-button" switch>
                                    Include Deceased Trainees
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2"
                                      variant="success"
                                      @click="getCsv">
                                <b-spinner v-if="searching" small type="grow"/>
                                Export</b-button>
                            <b-button class="mr-2"
                                      variant="dark"
                                      @click="getPdf">
                                <b-spinner v-if="searching" small type="grow"/>
                                Generate PDF</b-button>
                            <b-button variant="info"
                                      @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import _ from 'underscore';
import PickerOfDates from '@/components/shared/PickerOfDates';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";


@Component({
    components: {
        PickerOfDates
    }
})
export default class TraineeStats extends Vue {
    isSearching = false;

    selectedDistricts = [];
    selectedCouncils = [];
    yearRange = [null,null];
    includeLocalBreakdown = false;
    includeDeceased = false;
    enrolledGraduatedOnly = false;

    get includeOnlyEnrolledGraduated() {
        return this.enrolledGraduatedOnly;
    }

    set includeOnlyEnrolledGraduated(val) {
        this.enrolledGraduatedOnly = val;
        //Can't include deceased in only including enrolled/graduated
        if (!!this.enrolledGraduatedOnly) {
            this.includeDeceased = false;
        }
    }

    get searching() {
        return this.isSearching;
    }

    get districtOptions() {
        return _.map(this.$store.getters['common/districts'], (d) => {
            return {
                value: d.name.toUpperCase(),
                text: d.name
            };
        });
    }

    get councilOptions() {
        const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);
        return _.map(councils, (cs, district) => {
            return {
                label: district,
                options: _.map(cs, (c) => {
                    return {
                        value: c.displayId,
                        text: sprintf('%s (%s)', c.name, c.displayId)
                    };
                })
            };
        });
    }

    clearFilters() {
        this.selectedDistricts = [];
        this.selectedCouncils = [];
        this.yearRange = [null,null];
        this.includeLocalBreakdown = false;
        this.includeDeceased = false;
        this.enrolledGraduatedOnly = false;
    }

    async getCsv() {
        if (!this.yearRange[0] || !this.yearRange[1]) {
            alert("Pick a date range");
            return;
        }
        try {
            await download('POST', '/api/v1/export/trainee-stats', JSON.stringify({
                districts: this.selectedDistricts,
                councils: this.selectedCouncils,
                yearRange: [this.yearRange[0].getFullYear() + "-01-01T00:00:00", this.yearRange[1].getFullYear() + "-12-31T23:59:59"],
                includeLocalBreakdown: this.includeLocalBreakdown,
                enrolledGraduatedOnly: this.enrolledGraduatedOnly,
                includeDeceased: this.includeDeceased
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getPdf() {
        if (!this.yearRange[0] || !this.yearRange[1]) {
            alert("Pick a date range");
            return;
        }
        try {
            await download('POST', '/api/v1/report/trainee-stats', JSON.stringify({
                districts: this.selectedDistricts,
                councils: this.selectedCouncils,
                yearRange: [this.yearRange[0].getFullYear() + "-01-01T00:00:00", this.yearRange[1].getFullYear() + "-12-31T23:59:59"],
                includeLocalBreakdown: this.includeLocalBreakdown,
                enrolledGraduatedOnly: this.enrolledGraduatedOnly,
                includeDeceased: this.includeDeceased
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Trainee Statistics', null, true));
        _.each([
            'common/loadDistricts',
            'common/loadCouncils'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
    }
}
</script>